import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { colors } from './variables';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Regular.eot');
    src: local('/fonts/Montserrat Regular'), local('fonts/Montserrat-Regular'),
        url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('/fonts/Montserrat-Regular.woff') format('woff'),
        url('/fonts/Montserrat-Regular.ttf') format('truetype'),
        url('/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

	@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./fonts/Montserrat-Bold.eot');
    src: local('/fonts/Montserrat Bold'), local('fonts/Montserrat-Bold'),
        url('/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('/fonts/Montserrat-Bold.woff') format('woff'),
        url('/fonts/Montserrat-Bold.ttf') format('truetype'),
        url('/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  }

  @font-face {
    font-family: 'Fira Sans';
    src: url('/fonts/FiraSans-Black.eot');
    src: local('/fonts/Fira Sans Black'), local('fonts/FiraSans-Black'),
        url('/fonts/FiraSans-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FiraSans-Black.woff2') format('woff2'),
        url('/fonts/FiraSans-Black.woff') format('woff'),
        url('/fonts/FiraSans-Black.ttf') format('truetype'),
        url('/fonts/FiraSans-Black.svg#FiraSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
  }


  *, *:before, *:after {
    box-sizing: border-box;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 1;
  }

  ul {
    list-style-type: none;
  }

  a, button {
    text-decoration: none;
    color: ${colors.lightShade};

    :focus,:active,:hover {
      outline: 0;
    }
  }
`;

export default GlobalStyle;
