import React from 'react';
import PropTypes from 'prop-types';

import { ButtonWrapper } from './Button.style';

const propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary']),
  theme: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['small', 'regular', 'big']),
  disabled: PropTypes.bool,
  centered: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {
  appearance: 'primary',
  className: undefined,
  theme: 'light',
  size: 'regular',
  disabled: false,
  centered: false,
  block: false,
  handleClick: () => {},
};

const Button = ({
  children,
  handleClick,
  appearance,
  size,
  disabled,
  block,
  centered,
  theme,
  className,
}) => (
  <ButtonWrapper
    appearance={appearance}
    theme={theme}
    size={size}
    disabled={disabled}
    block={block}
    centered={centered}
    onClick={handleClick}
    className={className}
  >
    {children}
  </ButtonWrapper>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
