import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { Item } from './NavigationItem.style';

const propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const NavigationItem = ({ name, url }) => {
  const external = url.match(/(http|https):\/\//);

  return (
    <Item>
      {external ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      ) : (
        <Link to={url}>{name}</Link>
      )}
    </Item>
  );
};

NavigationItem.propTypes = propTypes;

export default NavigationItem;
