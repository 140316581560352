import Styled from 'styled-components';

import Modal from '../../common/Modal/Modal';

export const StyledCookieConsentModal = Styled(Modal)`
	width: 99%;
	position: relative;
	align-self: flex-end;
	flex-direction: row;
`;

export const CookieContent = Styled.div`
	font-size: 1.6rem;
	line-height: 1.7rem;
	max-height: 42rem;
`;
