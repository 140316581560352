import React from 'react';
import { I18nextProvider, withTranslation } from 'react-i18next';

import i18next from './i18n';

// eslint-disable-next-line import/prefer-default-export
export const withTrans = WrappedComponent => {
  // eslint-disable-next-line no-param-reassign
  WrappedComponent = withTranslation()(WrappedComponent);

  return props => (
    <I18nextProvider i18n={i18next}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent {...props} language={i18next.language} />
    </I18nextProvider>
  );
};
