import Styled from 'styled-components';

import { colors, breakpoints, sizes } from '../../styles/variables';

export const Item = Styled.li`
  letter-spacing: .5rem;
  font-size: ${sizes.nav.phoneS};
  padding: 1rem 0;

  a {
    color: ${colors.brandColor};

    :hover {
      text-decoration: underline;
    }
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.nav.tablet};
      :not(:first-of-type) {
        padding-left: 2rem;
      }
  }
  @media ${breakpoints.desktop} {
    font-size: ${sizes.nav.desktop};
  }
  @media ${breakpoints.desktopL} {
    font-size: ${sizes.nav.desktopL};
  }
`;

export default {
  Item,
};
