import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FacebookSquare } from '@styled-icons/boxicons-logos/FacebookSquare';
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';

import { H6 } from '../../styles/commonStyles';

import {
  Container,
  Top,
  Bottom,
  LeftColumn,
  RightColumn,
  About,
  Logo,
  Social,
  ListBox,
  List,
  ListItem,
} from './Footer.style';

const propTypes = {
  image: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const Footer = ({ image, list }) => {
  const isExternal = url => url.match(/(http|https):\/\//);
  const { t } = useTranslation('translations');

  return (
    <Container>
      <Top>
        <LeftColumn>
          <Logo>
            <Link to="/">
              <img src={image} alt="Logo Devbrains" />
            </Link>
          </Logo>
          <About>{t('common.footer.about')}</About>
          <Social>
            <a
              href="https://www.facebook.com/devbrainspl/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookSquare size="25" color="white" />
            </a>
            <a
              href="https://www.linkedin.com/company/devbrains-pl"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedinSquare size="25" color="white" />
            </a>
          </Social>
        </LeftColumn>
        <RightColumn>
          <ListBox>
            <H6 theme="dark">DEVBRAINS</H6>
            <List>
              {list.map(({ id, name, url }) => (
                <ListItem key={id}>
                  {isExternal(url) ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {name}
                    </a>
                  ) : (
                    <Link to={url}>{name}</Link>
                  )}
                </ListItem>
              ))}
            </List>
          </ListBox>
          <ListBox>
            <H6 theme="dark">{t('common.footer.columns.contact')}</H6>
            <List>
              <ListItem>
                <a href="mail to:contact@devbrains.pl">contact@devbrains.pl</a>
              </ListItem>
              <ListItem>ul. PCK 11/1 41-813 Zabrze</ListItem>
              <ListItem>
                <a href="tel:+48512562999">+48 512 562 999</a>
              </ListItem>
              <ListItem>NIP 648 254 33 04</ListItem>
            </List>
          </ListBox>
        </RightColumn>
      </Top>
      <Bottom>
        {t('common.footer.rights')} {new Date().getFullYear()}
      </Bottom>
    </Container>
  );
};

Footer.propTypes = propTypes;

export default Footer;
