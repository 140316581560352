import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Navigation from '../Navigation/Navigation';

import { Container, Logo, StyledHeader } from './Header.style';

const propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  image: PropTypes.string.isRequired,
  isBlog: PropTypes.bool.isRequired,
  handleToggleContact: PropTypes.func.isRequired,
};

const Header = ({ list, image, isBlog, handleToggleContact }) => (
  <StyledHeader>
    <Container>
      <Logo>
        <Link to="/">
          <img src={image} alt="Logo Devbrains" />
        </Link>
      </Logo>
      {!isBlog && <Navigation list={list} handleToggleContact={handleToggleContact} />}
    </Container>
  </StyledHeader>
);

Header.propTypes = propTypes;

export default Header;
