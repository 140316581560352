import styled from 'styled-components';

import { colors, breakpoints, fonts } from '../../styles/variables';

export const Container = styled.footer`
  display: flex;
  padding: 2rem;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.darkShade};
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media ${breakpoints.tabletPortrait} {
    padding: 5rem;
    min-height: 100%;
    text-align: left;
  }

  @media ${breakpoints.tabletLandscape} {
    padding: 5rem;
    height: 30rem;
  }

  @media ${breakpoints.desktop} {
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tabletLandscape} {
    flex-direction: row;
  }
`;

export const LeftColumn = styled.div`
  @media ${breakpoints.tabletLandscape} {
    width: 50%;
  }
`;

export const RightColumn = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tabletPortrait} {
    padding: 2rem 0 5rem;
  }

  @media ${breakpoints.tabletLandscape} {
    flex-direction: row;
    padding: 0;
  }
`;

export const About = styled.p`
  font-family: ${fonts.primaryFont};
  color: ${colors.lightAccent};
  font-size: 1.4rem;
  margin: 1rem 0;

  @media ${breakpoints.tabletLandscape} {
    margin: 2rem 0;
  }
`;

export const Social = styled.div`
  a {
    margin-right: 1rem;
  }

  svg {
    :hover {
      fill: ${colors.lightAccent};
    }
  }
`;

export const Bottom = styled.div`
  width: 100%;
  font-family: ${fonts.primaryFont};
  color: ${colors.lightAccent};
  font-size: 1.2rem;
`;

export const Logo = styled.div`
  width: 20rem;
  margin: 0 auto;
  padding: 2rem 0 1rem;

  @media ${breakpoints.tabletPortrait} {
    margin: 0;
    padding: 0;
  }
`;

export const ListBox = styled.div`
  text-align: center;
  margin: 2rem 0 0;

  @media ${breakpoints.tabletPortrait} {
    text-align: left;
  }

  @media ${breakpoints.tabletLandscape} {
    margin: 0 5rem;
  }
`;

export const List = styled.ul`
  margin-top: 0;
  padding-inline-start: 0;
`;

export const ListItem = styled.li`
  font-family: ${fonts.primaryFont};
  color: ${colors.lightAccent};
  font-size: 1.2rem;
  margin: 1rem 0;

  a {
    text-decoration: none;
    color: ${colors.lightAccent};
    font-size: 1.2rem;
    margin: 1rem 0;

    :hover {
      color: ${colors.lightShade};
    }
  }
`;
