import styled, { css } from 'styled-components';

import { colors, themes } from '../../styles/variables';

const sizes = {
  small: {
    width: '16rem',
    height: '3.2rem',
  },
  regular: {
    width: '22rem',
    height: '4.8rem',
  },
  big: {
    width: '22rem',
    height: '6.4rem',
  },
};

const buttonTextColors = {
  primary: {
    light: colors.brandColor,
    dark: colors.lightShade,
  },
  secondary: {
    light: colors.darkAccent,
    dark: colors.lightShade,
  },
};

export const ButtonWrapper = styled.button`
  display: block;
  width: ${({ size }) => sizes[sizes[size] ? size : 'regular'].width};
  height: ${({ size }) => sizes[sizes[size] ? size : 'regular'].height};
  line-height: calc(${({ size }) => sizes[sizes[size] ? size : 'regular'].height} - 0.2rem);
  font-size: 1.8rem;
  ${({ block }) => block && 'width: 100%;'}
  background-color: ${({ theme, appearance }) =>
    appearance === 'primary' ? colors.brandColor : buttonTextColors[appearance][theme]};
  color: ${({ theme }) => themes[theme].buttonColor};
  border: 2px solid ${({ theme, appearance }) =>
    appearance === 'primary' ? colors.brandColor : buttonTextColors[appearance][theme]};
  border-radius: 5rem;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  ${({ centered }) => centered && 'margin: 0 auto;'}
  padding: 0;
  cursor: pointer;
  transition: all 0.1s;

  :hover {
    background-color: transparent;
		color: ${({ theme }) => themes[theme].buttonHoverColor};
  }
`;

export default {
  ButtonWrapper,
};
