import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { withTrans } from '../../i18n/withTranslation';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Cookie from '../CookieConsent/CookieConsent';
import GlobalStyles from '../../styles/globalStyles';

import { Main } from './Layout.style';

const propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const Layout = ({ children, t, path }) => {
  const contactRef = useRef(null);

  const isBlog = path.startsWith('/blog');

  const handleClick = () => {
    const { top } = contactRef.current.getBoundingClientRect();
    window.scrollTo(0, top);
  };

  const navigatonList = [
    {
      id: 0,
      name: t('common.menu.home'),
      url: '/',
    },
    {
      id: 1,
      name: t('common.menu.blog'),
      url: '/blog',
    },
  ];

  return (
    <>
      <GlobalStyles />
      <Cookie />
      <Header
        handleToggleContact={handleClick}
        list={navigatonList}
        image="/img/devbrains_logo_black.svg"
        isBlog={isBlog}
      />
      <Main>{React.cloneElement(children, { contactRef, handleClick })}</Main>
      <Footer image="/img/devbrains_logo_white.svg" list={navigatonList} />
    </>
  );
};

Layout.propTypes = propTypes;

export default withTrans(Layout);
