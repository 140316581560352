import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../styles/variables';

import {
  StyledModal,
  StyledCancelButton,
  StyledButton,
  Overlay,
  Header,
  Body,
  ContentWrapper,
  ButtonsWrapper,
  CloseIcon,
} from './Modal.style';

const propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  buttonSize: PropTypes.oneOf(['small', 'regular', 'big']),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  overlayColor: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const defaultProps = {
  header: null,
  confirmLabel: null,
  cancelLabel: null,
  onConfirm: null,
  onCancel: null,
  buttonSize: 'regular',
  className: undefined,
  overlayColor: `${colors.brandColorTransparent}`,
  theme: 'light',
};

const Modal = ({
  header,
  isOpen,
  onClose,
  children,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  buttonSize,
  className,
  overlayColor,
  theme,
}) => {
  if (!isOpen) return null;
  const node = useRef();

  const Buttons = () => (
    <ButtonsWrapper theme={theme}>
      {cancelLabel && (
        <StyledCancelButton theme={theme} size={buttonSize} handleClick={onCancel}>
          {cancelLabel}
        </StyledCancelButton>
      )}
      {confirmLabel && (
        <StyledButton appearance="primary" theme={theme} size={buttonSize} handleClick={onConfirm}>
          {confirmLabel}
        </StyledButton>
      )}
    </ButtonsWrapper>
  );

  const isMouseOverComponent = e => {
    if (!!node.current && node.current.contains(e.target)) {
      return;
    }

    onClose();
  };

  if (overlayColor !== 'transparent')
    useEffect(() => {
      window.addEventListener('mousedown', isMouseOverComponent);

      return () => {
        window.removeEventListener('mousedown', isMouseOverComponent);
      };
    }, []);

  return (
    <Overlay background={overlayColor}>
      <StyledModal ref={node} className={className} theme={theme}>
        <CloseIcon onClick={onClose} theme={theme} />
        <Header theme={theme}>{header}</Header>
        <ContentWrapper>
          <Body theme={theme}>{children}</Body>
          <Buttons />
        </ContentWrapper>
      </StyledModal>
    </Overlay>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
