import Styled from 'styled-components';
import { Close } from '@styled-icons/material/Close';

import { breakpoints, fonts, themes, colors } from '../../styles/variables';
import Button from '../Button/Button';

export const Overlay = Styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  background: ${({ background }) => background};
  pointer-events: ${({ background }) => (background === 'transparent' ? 'none' : ' all')};

  @media ${breakpoints.tabletPortrait} {
    align-items: center;
    justify-content: center;
  }
`;

export const StyledModal = Styled.div`
  position: relative;
  overflow: auto;
  background: ${({ theme }) => themes[theme].background};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  pointer-events: initial;
  box-shadow: 0 .3rem .4rem 0 rgba(0, 0, 0, 0.14), 0 .1rem .8rem 0 rgba(0, 0, 0, 0.12), 0 .3rem .3rem -.2rem rgba(0, 0, 0, 0.4);
  padding: 2rem;
`;

export const CloseIcon = Styled(Close)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  color: ${({ theme }) => themes[theme].paragraph};
  cursor: pointer;
`;

export const Header = Styled.h3`
  color: ${({ theme }) => themes[theme].heading};
  text-transform: uppercase;
  margin-right: 2rem;
  font-size: 2.4rem;
  font-weight: 700;
  font-family: ${fonts.primaryFontBold};
`;

export const Body = Styled.div`
  color: ${({ theme }) => themes[theme].paragraph};

  @media ${breakpoints.tabletPortrait} {
    flex: 3;
  }
`;

export const ContentWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;

  @media ${breakpoints.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ButtonsWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 2rem;
  background: ${({ theme }) => themes[theme].background};

  @media ${breakpoints.tabletPortrait} {
    margin-top: 0;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const StyledCancelButton = Styled(Button)`
  width: 80%;
  text-align: center;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => (theme === 'light' ? colors.lightShade : colors.brandColor)};

  :hover,:active {
    color: ${({ theme }) => (theme === 'light' ? colors.brandColor : colors.darkShade)};
  }

  @media ${breakpoints.phone} {
    width: 100%;
  }

  @media ${breakpoints.tabletPortrait} {
    width: auto;
    margin: 0 1rem 0;
  }

  @media ${breakpoints.tabletLandscape} {
    margin: 0 5rem 0;
  }
`;

export const StyledButton = Styled(Button)`
  width: 100%;
  
  @media ${breakpoints.tabletPortrait} {
    width: 22rem;
  }
`;
