import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { StyledCookieConsentModal, CookieContent } from './CookieConsent.style';

const CookieConsent = () => {
  const { t } = useTranslation('translations');
  const [showModal, setShowModal] = useState(true);

  const onAcceptCookies = () => {
    Cookies.set('dbr.cookie', true, 365);
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(!showModal);
  };

  if (Cookies.get('dbr.cookie')) return null;

  return (
    <StyledCookieConsentModal
      header={t('common.cookies.heading')}
      confirmLabel={t('common.cookies.confirmLabel')}
      buttonSize="regular"
      isOpen={showModal}
      onConfirm={onAcceptCookies}
      onCancel={closeModal}
      onClose={closeModal}
      overlayColor="transparent"
      theme="light"
    >
      <CookieContent>{t('common.cookies.message')}</CookieContent>
    </StyledCookieConsentModal>
  );
};

export default CookieConsent;
