import Styled from 'styled-components';

import { breakpoints } from '../../styles/variables';

export const StyledHeader = Styled.header`
  position: absolute;
  width: 100%;
`;

export const Container = Styled.div`
  box-sizing: border-box;
  background-color: transparent;
  margin: 0 auto;
  padding: 1rem 2rem;
  height: 8.8rem;

  @media ${breakpoints.tabletPortrait} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 5rem;
  }
`;

export const Logo = Styled.div`
  width: 20rem;
  margin: 0 auto;
  padding: 2rem 0 1rem;

  @media ${breakpoints.tabletPortrait} {
    margin: 0;
    padding: 0rem;
  }
`;

export default {
  StyledHeader,
  Logo,
  Container,
};
