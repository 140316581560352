export const colors = {
  trainingoYellow: '#FDFF00',
  trainingoGrey: '#342E37',
  pubnowaRed: '#BF2829',
  pubnowaBlack: '#231F20',
  lightShade: '#f3f6f3',
  lightAccent: '#87c3cf',
  brandColor: '#22a7f0',
  brandColorTransparent: 'rgba(34,167,240,.1)',
  darkAccent: '#757885',
  darkShade: '#1c293e',
  heroHeading: '#1e073b',
  heroParagraph: '#514f54',
  red: '#f00',
  white: '#fff',
};

export const fonts = {
  primaryFont: `'Montserrat', sans-serif`,
  primaryFontBold: `'Montserrat-Bold', sans-serif`,
  secondaryFont: `'Fira Sans', sans-serif`,
};

export const breakpoints = {
  phoneS: '(max-width: 359px)',
  phone: '(min-width: 360px)',
  tabletPortrait: '(min-width: 600px)',
  tabletLandscape: '(min-width: 900px)',
  desktop: '(min-width: 1200px)',
  desktopL: '(min-width: 1800px)',
};

export const sizes = {
  h1: {
    phoneS: '3rem',
    phone: '4rem',
    tabletPortrait: '5rem',
    tabletLandscape: '5rem',
    desktop: '7rem',
    desktopL: '8rem',
  },
  h2: {
    phoneS: '2rem',
    phone: '3.5rem',
    tabletPortrait: '4rem',
    tabletLandscape: '5rem',
    desktop: '6rem',
    desktopL: '8rem',
  },
  paragraph: {
    phoneS: '2rem',
    phone: '2.4rem',
    tabletPortrait: '2.8rem',
    tabletLandscape: '2.8em',
    desktop: '3.6rem',
    desktopL: '4rem',
  },
  nav: {
    phoneS: '1.8rem',
    phone: '2rem',
    tabletPortrait: '1.8rem',
    tabletLandscape: '1.8rem',
    desktop: '1.6rem',
    desktopL: '1.6rem',
  },
};

export const themes = {
  light: {
    background: colors.white,
    paragraph: colors.heroParagraph, // colors.darkShade,
    heading: colors.darkShade,
    buttonColor: colors.white,
    buttonHoverColor: colors.brandColor,
  },
  dark: {
    background: colors.darkShade,
    paragraph: colors.lightShade,
    heading: colors.lightShade,
    buttonColor: colors.lightShade,
    buttonHoverColor: colors.brandColor,
  },
};
