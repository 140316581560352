import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import pl from '../locale/pl-PL/translations.json';
import en from '../locale/en-US/translations.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      'pl-PL': {
        translations: pl,
      },
      pl: {
        translations: pl,
      },
      en: {
        translations: en,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    returnObjects: true,
    preload: true,
  });

i18n.languages = ['pl-PL', 'en'];

export default i18n;
