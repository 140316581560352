import styled from 'styled-components';

import { breakpoints } from '../../styles/variables';

export const NavigationPanel = styled.nav`
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoints.tabletPortrait} {
    background-color: transparent;
  }
`;

export const NavigationItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-inline-start: 0;
  padding: 0;

  @media ${breakpoints.tabletPortrait} {
    width: auto;
  }
`;

export const ContactWrapper = styled.div`
  display: none;

  @media ${breakpoints.tabletPortrait} {
    margin-left: 4rem;
  }

  @media ${breakpoints.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export default {
  ContactWrapper,
  NavigationPanel,
  NavigationItems,
};
