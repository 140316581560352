import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../common/Button/Button';

import { NavigationPanel, NavigationItems, ContactWrapper } from './Navigation.style';
import NavigationItem from './NavigationItem';

const propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleToggleContact: PropTypes.func.isRequired,
};

const Navigation = ({ list, handleToggleContact }) => {
  const { t } = useTranslation('translations');

  return (
    <NavigationPanel>
      <NavigationItems>
        {list.map(({ id, name, url }) => (
          <NavigationItem key={id} name={name} url={url} />
        ))}
        <ContactWrapper>
          <Button handleClick={handleToggleContact}>{t('common.header.ctaLabel')}</Button>
        </ContactWrapper>
      </NavigationItems>
    </NavigationPanel>
  );
};

Navigation.propTypes = propTypes;

export default Navigation;
