import styled, { css } from 'styled-components';

import { fonts, colors, breakpoints, sizes, themes } from './variables';

const commonHeaderStyles = css`
  display: inline-block;
  overflow: wrap;
  text-align: center;
  font-family: ${fonts.secondaryFont};
  font-weight: 700;
  letter-spacing: 0.5rem;
  color: ${({ theme }) => (themes[theme] && themes[theme].heading) || colors.lightShade};
  margin: 1rem 0;
`;

export const H1 = styled.h1`
  ${commonHeaderStyles}

  font-size: ${sizes.h1.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h1.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h1.tabletPortrait};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h1.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h1.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h1.desktopL};
  }
`;

export const H2 = styled.h2`
  ${commonHeaderStyles}

  font-size: ${sizes.h2.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h2.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h2.tabletPortrait};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h2.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h2.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h2.desktopL};
  }
`;

export const H6 = styled.h6`
  ${commonHeaderStyles}

  font-size: 1.4rem;
`;

export const Paragraph = styled.p`
  font-size: ${sizes.paragraph.phoneS};
  margin: 1rem 0;
  letter-spacing: 0.5rem;

  color: ${({ theme }) => themes[theme] && themes[theme].paragraph};

  @media ${breakpoints.phone} {
    font-size: ${sizes.paragraph.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.paragraph.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.paragraph.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.paragraph.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.paragraph.desktopL};
  }
`;
